<template>
  <form
    class="c-search-header__form"
    @submit.prevent="onSearchSubmit"
  >
    <div class="c-search-header__query">
      <label
        class="c-search-header__query-wrapper"
        for="search"
      >
        <input
          id="search"
          ref="searchInput"
          v-model="searchTerm"
          :aria-label="translations.searchPlaceholderText"
          :placeholder="translations.searchPlaceholderText"
          minlength="3"
          required
          class="c-input"
          name="search"
          type="search"
        >
      </label>

      <button
        type="submit"
        class="c-button--icon"
      >
        <Icon
          :icon="iconSearch"
          :size="20"
        />
      </button>
    </div>
  </form>
</template>

<script>
import {
  computed,
  ref,
  watchEffect,
  onMounted
} from 'vue'
import Icon from '@/components/Icon.vue'
import iconSearch from '@/assets/img/icons/search.svg'
import searchConfig from '@/composition/search/searchConfig'
import searchQuery from '@/composition/search/searchQuery'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: { Icon },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const { addTerm } = searchQuery.searchQueryUse()

    const searchTerm = ref('')
    const searchInput = ref(null)
    const showClearButton = ref(false)
    const trackStartTyping = ref(false)

    onMounted(() => {
      searchInput.value.oninvalid = (e) => {
        if (!e.target.validity.valid || searchTerm.value.trim().length === 0) {
          e.target.setCustomValidity(searchConfiguration.translations.searchValidationMessage)
        }
      }
      searchInput.value.oninput = (e) => {
        e.target.setCustomValidity('')
      }
    })

    watchEffect(() => {
      showClearButton.value = !!searchTerm.value
      if (showClearButton.value && !trackStartTyping.value) {
        trackStartTyping.value = true
        gtmUtil.startSearchTyping()
      }
    })

    const onSearchSubmit = () => {
      if (searchTerm.value && searchTerm.value.trim().length > 2) {
        addTerm(searchTerm.value)
        gtmUtil.searchTerm(searchTerm.value)
        searchTerm.value = ''
      }
    }

    return {
      searchInput,
      searchTerm,
      showClearButton,
      iconSearch,
      translations: computed(() => searchConfiguration.translations),
      onSearchSubmit
    }
  }
}
</script>
