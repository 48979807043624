import { computed, reactive } from 'vue'
import { useMq } from 'vue3-mq'
import searchApi from '@/services/search'

const searchResultState = reactive({
  loading: false,
  allItemsLoaded: false,
  totalCount: 0,
  hasResult: false,
  items: [],
  relatedProducts: {},
  relatedRecipes: {}
})

export default {
  useSearchResult() {
    const state = searchResultState
    const isMobile = useMq().current === 'mobile' || useMq().current === 'tablet'

    function setGifsToShow(items) {
      const showGifThreshold = isMobile ? 5 : 3
      let nextIndexToCheck = (showGifThreshold) - 1

      items.forEach((item, index) => {
        if (index === nextIndexToCheck) {
          if (item.animatedImage) {
            item.showGif = true
            nextIndexToCheck += showGifThreshold
          } else nextIndexToCheck += 1
        }
      })

      return items
    }

    async function fetchData(searchQuery) {
      state.allItemsLoaded = false
      const skip = searchQuery.take * (searchQuery.page - 1)
      const {
        data: {
          total,
          items,
          relatedProducts,
          relatedRecipes
        }
      } = await searchApi.getFavoriteItems(searchQuery, { skip })
      state.totalCount = total
      state.hasResult = total > 0

      state.items = setGifsToShow(items)
      if (relatedProducts) {
        state.relatedProducts = relatedProducts
      }
      if (relatedRecipes) {
        state.relatedRecipes = relatedRecipes
      }

      if (searchQuery.take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function fetchExtraItems(searchQuery) {
      const skip = searchQuery.take * (searchQuery.page - 1)
      const response = await searchApi.getFavoriteItems(searchQuery, { skip })

      const extraItems = setGifsToShow(response.data.items)
      state.items.push(...extraItems)

      if (skip + searchQuery.take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function search(searchQuery) {
      if (!state.loading) {
        state.items = []
        state.relatedProducts = []
        state.relatedRecipes = []
        state.loading = true
        try {
          await fetchData(searchQuery)
        } finally {
          state.loading = false
        }
      }
    }

    async function searchMore(searchQuery) {
      if (state.totalCount === state.items.length || state.allItemsLoaded) {
        return
      }

      if (!state.loading) {
        state.loading = true
        try {
          fetchExtraItems(searchQuery)
        } finally {
          state.loading = false
        }
      }
    }

    return {
      searchResult: state,
      relatedProducts: computed(() => state.relatedProducts),
      relatedRecipes: computed(() => state.relatedRecipes),
      loading: computed(() => state.loading),
      allItemsLoaded: computed(() => state.allItemsLoaded),
      search,
      searchMore
    }
  }
}
