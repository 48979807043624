import { createRouter, createWebHistory } from 'vue-router'

const favoritesSearchRouter = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      name: 'home'
    }
  ]
})

export default favoritesSearchRouter
