<template>
  <div>
    <!-- Results list -->
    <component
      :is="gridComponent"
      :items="searchResult.items"
      :buy-button-label="translations.buyButtonLabel"
    />

    <!-- Search more -->
    <div v-if="!loading && searchResult.items.length && !allItemsLoaded">
      <div class="u-flex u-flex-justify-center u-mt--s06">
        <router-link
          :to="{ name: 'filters', query: getNextPageQuery() }"
          class="c-button"
          @click.prevent="searchMoreResults"
        >
          {{ translations.loadMoreButtonText }}
        </router-link>
      </div>
    </div>

    <!-- No items added state -->
    <template v-if="!loading">
      <div
        v-if="noItemsAdded"
        class="u-text-align--center u-p--s06"
      >
        <Icon
          :icon="iconSearch"
          :size="64"
          class="u-text-light-gray"
        />

        <div
          class="u-text-dark-gray u-mt--s04"
          v-html="translations.noItemsAddedHtml"
        />
        <div
          v-if="productOverviewLink"
          class="u-flex u-flex-justify-center"
        >
          <a
            :href="productOverviewLink.url"
            class="c-button"
            v-text="productOverviewLink.text"
          />
        </div>
      </div>

      <!-- Empty state -->
      <div
        v-else-if="!searchResult.items.length"
        class="u-text-align--center u-p--s06"
      >
        <Icon
          :icon="iconSearch"
          :size="64"
          class="u-text-light-gray"
        />

        <div
          class="u-text-dark-gray u-mt--s04"
          v-html="translations.noResultHtml"
        />
      </div>
    </template>

    <!-- Loading state -->
    <div
      v-else
      class="u-flex u-p--s08 u-flex-align-center u-flex-justify-center u-height-min-50vh"
    >
      <div class="c-spinner c-spinner--lg" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import ProductGrid from '@/components/search/ProductGrid.vue'

import iconSearch from '@/assets/img/icons/search.svg'
import searchFactory from '@/composition/favorites/favoritesSearchFactory'
import searchQueryState from '@/composition/favorites/favoritesSearchQuery'
import searchConfig from '@/composition/favorites/favoritesSearchConfig'
import router from '@/composition/favorites/favoritesSearchRouter'

export default {
  components: {
    Icon,
    ProductGrid
  },
  setup() {
    const { loading, allItemsLoaded } = searchFactory.useSearchResult()
    const { searchConfiguration, productOverviewLink } = searchConfig.searchConfigurationUse()
    const {
      searchQuery,
      hasExtendedFilters,
      searchType,
      searchResult,
      searchMoreResults
    } = searchQueryState.searchQueryUse()
    const translations = computed(() => searchConfiguration.translations)
    const gridComponent = computed(() => `${searchType.value}Grid`)
    const noItemsAdded = computed(() => !searchQuery.ids.length)

    const getNextPageQuery = () => {
      const { query } = router.currentRoute.value
      const page = query.page ? parseInt(query.page, 10) + 1 : 2

      return {
        ...query,
        page
      }
    }

    const totalCountText = computed(() => (translations.value
      ? translations.value.totalCountMessage.replace('{0}', searchResult.totalCount)
      : ''
    ))

    return {
      hasExtendedFilters,
      gridComponent,
      searchResult,
      loading,
      allItemsLoaded,
      noItemsAdded,
      iconSearch,
      totalCountText,
      translations,
      productOverviewLink,

      searchMoreResults,
      getNextPageQuery
    }
  }
}
</script>
