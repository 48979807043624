import { reactive } from 'vue'

const searchConfigurationState = reactive({
  searchConfiguration: {},
  baseUrl: '',
  productOverviewLink: null,
  initialized: false
})

export default {
  searchConfigurationUse(configuration, baseUrl, productOverviewLink) {
    const state = searchConfigurationState

    if (configuration && !state.initialized) {
      configuration.categories.forEach((c) => c.items.forEach((i) => { i.isBrand = c.isBrand }))
      state.searchConfiguration = configuration
      state.baseUrl = baseUrl
      state.productOverviewLink = productOverviewLink
      state.initialized = true
    }

    // Added check for changing config on different search type
    if (configuration && (configuration.type !== state.searchConfiguration.type)) {
      state.searchConfiguration = configuration
      state.baseUrl = baseUrl
      state.productOverviewLink = productOverviewLink
    }

    return {
      searchConfiguration: state.searchConfiguration,
      baseUrl: state.baseUrl,
      productOverviewLink: state.productOverviewLink
    }
  }
}
