<template>
  <div class="c-overview c-favorites u-pb--s08 o-container">
    <SearchInput v-if="isMobile" />

    <div class="c-overview-content">
      <SearchHeader
        :search-result="searchResult"
      />
      <SearchActiveTags class="u-mb--s06" />
      <SearchItems />
    </div>
  </div>

  <FavoritesRelatedItems class="u-pt--s10 u-pt--s06@mobile" />
</template>

<script>
import { watch, computed } from 'vue'
import { useMq } from 'vue3-mq'
import SearchItems from '@/components/favorites/FavoritesSearchItems.vue'
import SearchHeader from '@/components/favorites/FavoritesSearchHeader.vue'
import SearchInput from '@/components/favorites/FavoritesSearchInput.vue'
import SearchActiveTags from '@/components/favorites/FavoritesSearchActiveTags.vue'
import FavoritesRelatedItems from '@/components/favorites/FavoritesRelatedItems.vue'
import searchQueryState from '@/composition/favorites/favoritesSearchQuery'
import searchConfig from '@/composition/favorites/favoritesSearchConfig'
import routerHelper from '@/composition/favorites/favoritesSearchRouterHelper'
import router from '@/composition/favorites/favoritesSearchRouter'

export default {
  components: {
    FavoritesRelatedItems,
    SearchHeader,
    SearchItems,
    SearchInput,
    SearchActiveTags
  },
  props: {
    searchConfiguration: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    productOverviewLink: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      searchConfiguration,
      baseUrl,
      productOverviewLink
    } = props
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')
    searchConfig.searchConfigurationUse(searchConfiguration, baseUrl, productOverviewLink)
    router.addRoute({
      path: props.baseUrl,
      name: 'filters'
    })

    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, baseUrl)
    const routeData = getRouteData()
    routeData.language = props.language
    const { searchResult } = searchQueryState.searchQueryUse(routeData)

    watch(
      () => [searchResult.loading],
      () => {
        if (!searchResult.loading) {
          updateRoute()
        }
      }
    )

    return {
      isMobile,
      searchResult
    }
  }
}
</script>
