<template>
  <div v-if="showRelated">
    <RelatedProductsBlock
      v-if="relatedProducts.items?.length"
      :model="relatedProducts"
    />
    <RelatedRecipesBlock
      v-if="relatedRecipes.items?.length"
      :model="relatedRecipes"
    />
  </div>
</template>
<script>
import { computed } from 'vue'
import searchFactory from '@/composition/favorites/favoritesSearchFactory'
import RelatedProductsBlock from '@/components/RelatedProductsBlock.vue'
import RelatedRecipesBlock from '@/components/RelatedRecipesBlock.vue'

export default {
  components: {
    RelatedRecipesBlock,
    RelatedProductsBlock
  },
  setup() {
    const { relatedProducts, relatedRecipes } = searchFactory.useSearchResult()
    const showRelated = computed(() => relatedRecipes.value.items?.length || relatedProducts.value.items?.length)

    return {
      showRelated,
      relatedProducts,
      relatedRecipes
    }
  }
}
</script>
