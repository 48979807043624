<template>
  <div class="c-related-recipes-block">
    <Slider
      :title="model.title"
      :link="model.link"
      type="quarter"
    >
      <div
        v-for="(item, index) in model.items"
        :key="index"
        class="c-slide"
      >
        <RecipeCard
          :model="item"
          :media-sizes="mediaSizes"
        />
      </div>
    </Slider>
  </div>
</template>

<script>
import RecipeCard from '@/components/recipe/RecipeCard.vue'
import Slider from '@/components/Slider.vue'

export default {
  components: {
    RecipeCard,
    Slider
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      mediaSizes: {
        mobile: [324],
        tablet: [224],
        desktop: [268],
        wide: [328]
      }
    }
  }
}
</script>
