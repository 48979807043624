<template>
  <div
    v-if="totalSlides > 1"
    class="c-step-by-step__controls"
  >
    <button
      data-controls="prev"
      aria-controls="customize"
      tabindex="-1"
      :disabled="prevDisabled"
      class="c-carousel__navigation-button c-carousel__navigation-button-prev"
      @click="$emit('onPrev')"
    >
      <Icon
        :icon="iconChevronRight"
        :size="20"
        class="c-carousel__button"
      />
    </button>
    <button
      data-controls="next"
      aria-controls="customize"
      tabindex="-1"
      :disabled="nextDisabled"
      class="c-carousel__navigation-button c-carousel__navigation-button-next"
      @click="$emit('onNext')"
    >
      <Icon
        :icon="iconChevronRight"
        :size="20"
        class="c-carousel__button"
      />
    </button>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'

export default {
  components: {
    Icon
  },
  props: {
    totalSlides: {
      type: Number,
      required: true
    },
    prevDisabled: {
      type: Boolean,
      required: true
    },
    nextDisabled: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {
      iconChevronRight
    }
  }
}
</script>
