import searchQueryState from '@/composition/favorites/favoritesSearchQuery'
import searchConfig from '@/composition/favorites/favoritesSearchConfig'

export default {
  useRouterHelper(routerParam, baseUrl) {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const { searchQuery } = searchQueryState.searchQueryUse()
    const router = routerParam

    function updateRoute() {
      const searchParams = new URLSearchParams(window.location.search)
      const query = Object.fromEntries(searchParams.entries())
      const path = baseUrl

      // Set search term
      const termsUrl = searchQuery.terms.join('+')
      if (termsUrl !== '') {
        query.query = termsUrl
      } else {
        delete query.query
      }

      const defaultSorting = searchConfiguration.sortingOptions.find((x) => x.default).value
      if (searchQuery.sorting && searchQuery.sorting !== defaultSorting) {
        query.sorting = searchQuery.sorting
      } else {
        delete query.sorting
      }

      if (searchQuery.page !== 1) {
        query.page = searchQuery.page
      } else {
        delete query.page
      }

      router.push({
        path,
        query
      }).catch(() => {})
    }

    function getRouteData() {
      const {
        sortingOptions,
        type: searchType,
        contentLinkId
      } = searchConfiguration

      const searchParams = new URLSearchParams(window.location.search)
      const query = searchParams.get('query')
      const terms = (query || '').split('+').filter((t) => t)
      const sorting = router.currentRoute.value.query.sorting || sortingOptions.find((x) => x.default).value

      const pageStr = searchParams.get('page')
      const data = {
        searchType,
        terms,
        sorting,
        contentLinkId
      }

      if (pageStr) {
        data.page = parseInt(pageStr, 10)
      }

      return data
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}
