<template>
  <div class="c-step-by-step">
    <div class="c-step-by-step__content">
      <slot />
      <div class="c-step-by-step__text">
        <h6
          class="e-preamble"
          v-html="progressText(model)"
        />
        <component
          :is="headingTag"
          v-html="model.title"
        />
        <div
          v-if="model.text"
          v-html="model.text"
        />
        <a
          v-if="model.link && model.link.url"
          :href="model.link.url"
          v-bind="model.link.attributes"
          class="e-link--arrow"
        >{{ model.link.text }}</a>
      </div>
    </div>
    <div class="c-step-by-step__visual">
      <div class="c-step-by-step__visual-wrapper">
        <CVideo
          v-if="model && model.video"
          :model="videoModel"
          :type="model.video.type"
          class="c-step-by-step__video"
        />
        <Picture
          v-else-if="model.image"
          :src="model.image.src"
          :full-width="true"
          class="c-step-by-step__image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'

export default {
  components: {
    Picture,
    CVideo
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    isFirstItem: {
      type: Boolean,
      default: false
    },
    headingTag: {
      type: String,
      default: 'h3'
    },
    progress: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      model: {
        video,
        title
      },
      isFirstItem
    } = props
    const getPoster = (item) => (item.image && item.image.src)
      || item.video.poster

    const videoModel = ref({
      ...video,
      poster: getPoster(props.model),
      heading: title,
      autoplay: (isFirstItem && video?.autoplay)
    })

    const progressText = (item) => {
      if (item.subtitle) {
        return `${props.progress} - ${item.subtitle}`
      }

      return props.progress
    }

    return {
      videoModel,

      progressText,
      getPoster
    }
  }
}
</script>
