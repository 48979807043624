<template>
  <div class="c-products-block">
    <!-- NOTE: It's agreed to NOT show "see all" link for related products -->
    <Slider
      :title="model.title"
      :link="null"
      type="third"
    >
      <div
        v-for="(item, index) in model.items"
        :key="index"
        class="c-slide"
      >
        <ProductCard
          :model="item"
          :buy-button-label="model.buyButtonLabel"
          placement="product carousel"
        />
      </div>
    </Slider>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'
import Slider from '@/components/Slider.vue'

export default {
  components: {
    ProductCard,
    Slider
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      mediaSizes: {
        mobile: [292, 211],
        tablet: [234, 160],
        desktop: [304, 212],
        wide: [352, 240]
      }
    }
  }
}
</script>
